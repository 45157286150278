<template>
    <div class="trait-filter">
      <div v-if="width < 769" class="mobile">
        <div class="lab-dropdown region">
          <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownSort">
            <span class="lab-dropdown-btn-value">{{ selectedSort }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon />
            </span>
          </div>
          <div class="lab-dropdown-content" :class="{ 'show': isShowDropdown}">
            <ul>
              <li v-for="sortItem in sortList"
                  :key="sortItem.id"
                  @click="onSetSort(sortItem)">
                  {{ sortItem.title }}
              </li>
            </ul>
          </div>
        </div>
        <div class="lab-dropdown region">
          <div class="lab-dropdown-btn" @click="isShowDropdownTrait = !isShowDropdownTrait" ref="dropdownTrait">
            <span class="lab-dropdown-btn-value">{{ $store.state.traitsAndHealths.selectedTraitCategory.name }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon />
            </span>
          </div>
          <div class="lab-dropdown-content" :class="{ 'show': isShowDropdownTrait}">
            <ul>
              <li v-for="item in $store.state.traitsAndHealths.traitsCategories[selectedOrderResult.id]"
                  :key="item.id"
                  @click="$store.state.traitsAndHealths.selectedTraitCategory = item">
                  {{item.name}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="lab-dropdown region">
          <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownSort">
            <span class="lab-dropdown-btn-value">{{ selectedSort }}</span>
            <span class="lab-dropdown-btn-icon">
              <dropdown-icon />
            </span>
          </div>
          <div class="lab-dropdown-content" :class="{ 'show': isShowDropdown}">
            <ul>
              <li v-for="sortItem in sortList"
                  :key="sortItem.id"
                  @click="onSetSort(sortItem)">
                  {{ sortItem.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  data() {
    return {
      isShowDropdown: false,
      isShowDropdownTrait: false,

      sortList: [
        {
          id: 'HIGH_LOW',
          title: 'High-Low',
        },
        {
          id: 'LOW_HIGH',
          title: 'Low-High',
        },
        {
          id: 'A_Z',
          title: 'A-Z',
        },
        {
          id: 'Z_A',
          title: 'Z-A',
        },
      ],
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownSort;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownTrait;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownTrait = false;
        }
      } catch (error) {}
    },

    onSetSort(value) {
      this.$store.state.traitsAndHealths.selectedSort = value.id;
    },
  },
  computed: {
    selectedOrderResult() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResult'];
    },
    selectedSort() {
      const sort = this.$store.getters['traitsAndHealths/getSelectedSort'];
      if (sort === '') {
        return 'Sort by'
      }
      return this.sortList.find(x => x.id === sort).title;
    },
    width() {
      return this.$store.state.appConfig.layout.windowWidth;
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .lab-dropdown  {
    width: 50%;
    .lab-dropdown-content {
      width: 100%;
    }
    svg {
      fill: var(--lab-white);
    }
  }
  
  .lab-dropdown-btn  {
    color: var(--lab-white);
    background-color: var(--lab-carbon-fiber);
  }
}

.dark-layout .trait-filter .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-fight-sunrise-color);
  border: 2px solid var(--lab-fight-sunrise-color);
}

.dark-layout .trait-filter .lab-dropdown.region .lab-dropdown-btn svg {
  fill: var(--lab-fight-sunrise-color);
}
</style>
