<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162 194.4">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-2" d="M32.4,0C14.51,0,0,14.51,0,32.4V162c0,17.89,14.51,32.4,32.4,32.4h97.2c17.89,0,32.4-14.51,32.4-32.4V58.79c0-10.53-5.11-20.4-13.72-26.47L110.9,5.93C105.43,2.07,98.9,0,92.21,0H32.4Z"/>
        <path class="cls-1" d="M116.45,81.68c-10.59,0-19.21-8.62-19.21-19.21s8.62-19.21,19.21-19.21,19.21,8.62,19.21,19.21-8.62,19.21-19.21,19.21Zm0-29.42c-5.63,0-10.21,4.58-10.21,10.21s4.58,10.21,10.21,10.21,10.21-4.58,10.21-10.21-4.58-10.21-10.21-10.21Z"/>
        <path class="cls-1" d="M40.82,81.68c-10.59,0-19.21-8.62-19.21-19.21s8.62-19.21,19.21-19.21,19.21,8.62,19.21,19.21-8.62,19.21-19.21,19.21Zm0-29.42c-5.63,0-10.21,4.58-10.21,10.21s4.58,10.21,10.21,10.21,10.21-4.58,10.21-10.21-4.58-10.21-10.21-10.21Z"/>
        <path class="cls-1" d="M111,139.91c-1.15,0-2.3-.44-3.18-1.32-9.61-9.62-19.42-14.21-29.17-13.71-14.77,.79-24.95,13.21-25.06,13.34-1.56,1.93-4.39,2.24-6.32,.69-1.93-1.55-2.25-4.37-.71-6.31,.51-.64,12.68-15.64,31.5-16.7,12.46-.7,24.58,4.79,36.11,16.32,1.76,1.76,1.76,4.61,0,6.36-.88,.88-2.03,1.32-3.18,1.32Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>        
svg {
  height: 65px;
}

.cls-1 {
  fill: #fff;
}

.cls-2 {
  fill: #9e9e9e;
}

.dark-layout {
  .cls-1 {
    fill: #1bbfcc;
  }

  .cls-2 {
    fill: #1d2328;
    stroke: #1bbfcc;
    stroke-miterlimit: 10;
  }
}
</style>
