<template>
  <div v-if="isLoaded">
    <div class="traits-category mb-2 hidden-mobile">
      <div class="trait-count">
        <span></span> {{traitsResult.length}} Traits
      </div>
      <div class="traits-category-item" 
        :class="{'active' : $store.state.traitsAndHealths.selectedTraitCategory.id === item.id }" 
        v-for="item in $store.state.traitsAndHealths.traitsCategories[selectedOrderResult.id]" 
        :key="item.id" @click="$store.state.traitsAndHealths.selectedTraitCategory = item">
        {{item.name}}
      </div>
    </div>

    <trait-result-filter v-if="$store.state.appConfig.layout.windowWidth < 769"/>
    
    <div>
      <div class="traits-result-container" v-for="traitItem in traitsResult" :key="traitItem.id">
        <div class="trait-item hidden-mobile mb-2">
          <div class="trait-item-left">
            <b-img
              v-if="$store.state.appConfig.layout.skin === 'light'"
              :src="traitItem.trait.traitFeature.iconUrl" height="30"  width="30"
            />
            <b-img
              v-if="$store.state.appConfig.layout.skin === 'dark'"
              :src="traitItem.trait.traitFeature.darkIconUrl" height="30"  width="30"
            />
            <div class="ml-2">
              <div class="name">{{traitItem.trait.traitFeature.name}}</div>
              <div class="description">{{traitItem.trait.traitEffect.effect}}</div>
            </div>

          </div>
          <div class="trait-item-right">
            <div class="trait-rate-card" 
              :class="{ 'trait-rate-low': traitItem.trait.rate == 1, 
                        'trait-rate-intermediate': traitItem.trait.rate == 2, 
                        'trait-rate-high': traitItem.trait.rate == 3 }">
              {{traitItem.trait.rateText}}
            </div>
            <span class="icon-btn" @click="openDetail(traitItem)">
              <feather-icon
                icon="ArrowRightIcon"
                class="cursor-pointer lab-text-steel"
                size="16"
              />
            </span>
          </div>
        </div>
        <hr class="hidden-mobile"/>

        <div class="trait-item-mobile dashboard-card hidden-desktop">
          <div class="trait-item-top">
            <b-img
              v-if="$store.state.appConfig.layout.skin === 'light'"
              :src="traitItem.trait.traitFeature.iconUrl" height="30"  width="30"
            />
            <b-img
              v-if="$store.state.appConfig.layout.skin === 'dark'"
              :src="traitItem.trait.traitFeature.darkIconUrl" height="30"  width="30"
            />
            <div class="trait-rate-card" 
              :class="{ 'trait-rate-low': traitItem.trait.rate == 1, 
                        'trait-rate-intermediate': traitItem.trait.rate == 2, 
                        'trait-rate-high': traitItem.trait.rate == 3 }">
              {{traitItem.trait.rateText}}
            </div>
          </div>
          <div class="trait-item-bottom">
            <div>
              <div class="name">{{traitItem.trait.traitFeature.name}}</div>
              <div class="description">{{traitItem.trait.traitEffect.effect}}</div>
            </div>
            <div class="item-info text-right">
              <span  @click="openDetail(traitItem)">
                <circle-arrow-right-icon class="cursor-pointer"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleArrowRightIcon from '@/layouts/icons/CircleArrowRightIcon.vue';
import TraitResultFilter from '../TraitResultFilter.vue'

export default {
  components: {
    CircleArrowRightIcon,
    TraitResultFilter,
  },
  props: {
    categoryType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isLoaded: false,
      selectedCategory: {},
    }
  },
  methods: {
    onLoadTraitsResults() {
      this.isLoaded = false;
      const result = this.selectedOrderResult;
      if (result.orderResultStatus !== 0) {
        this.$store.dispatch('traitsAndHealths/fetchTraitsData', { 
          orderResultTraitHealthId: result.id,
          traitCategoryType: this.categoryType })
          .then(response => {
            if (response.statusCode === 200) {
              this.$store.commit('traitsAndHealths/setTraitsDataResults', { resultId: result.id, results: response.result });
              this.isLoaded = true;
            }
          });
      }
    },

    openDetail(traitItem) {
      this.$router.push({ name: 'order-result-trait-detail', params: { id: traitItem.trait.id, traitIds: traitItem.traitIds.join(',') } })
    },
  },
  computed: {
    traitsResult() {
      const results = this.$store.getters['traitsAndHealths/getTraitsDataResults'][this.selectedOrderResult.id];

      const findResult = results.find(x => x.traitCategory.id === this.$store.state.traitsAndHealths.selectedTraitCategory.id);
      if (!findResult) {
        return [];
      }
      const datas = findResult.traitList;

      if (this.selectedSort === 'A_Z') {
        datas.sort((a, b) => a.trait.traitFeature.name.localeCompare(b.trait.traitFeature.name));
      } else if (this.selectedSort === 'Z_A') {
        datas.sort((a, b) => b.trait.traitFeature.name.localeCompare(a.trait.traitFeature.name));
      } else if (this.selectedSort === 'HIGH_LOW') {
        datas.sort((a, b) => b.trait.rate - a.trait.rate);
      } else if (this.selectedSort === 'LOW_HIGH') {
        datas.sort((a, b) => a.trait.rate - b.trait.rate);
      }

      return datas;
    },
    selectedOrderResult() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResult'];
    },

    selectedSort() {
      return this.$store.getters['traitsAndHealths/getSelectedSort'];
    },
  },
  watch: {
    selectedSort(newValue, oldValue) {
      this.onLoadTraitsResults();
    },
  },
  mounted() {
    this.$root.$refs.Traits = this;
  },
}
</script>

<style lang="scss" scoped>
.trait-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--lab-black);
  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--lab-fight-sunrise-color);
    margin-right: 5px;
  }
}

.traits-category {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  padding: 10px;

  .traits-category-item {
    background: var(--lab-light-house);
    border-radius: 3px;
    color: var(--lab-stieglitz-silver);
    font-weight: bold;
    font-size: 0.9rem;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0 5px;
  }
  .traits-category-item.active {
    background: var(--lab-carbon-fiber);
    color: var(--lab-white);
  }
}

.dark-layout .traits-category .traits-category-item {
  background-color: var( --lab-neverything);
}
.dark-layout .traits-category .traits-category-item.active {
  color: var(--lab-white);
  background-color: var( --lab-neverything);
}

.dark-layout  {
  .lab-text-steel, .trait-count {
    color: var(--lab-white) !important;
  }
}
</style>
