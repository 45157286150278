<template>
  <div class="no-result-dashboard mx-auto">
    <div class="no-kit" v-if="orderResultStatus === null">
      <div class="mr-2">
        <no-kit-icon />
      </div>
      <div>
        <h5>NO RESULTS FOUND FOR THE SELECTED KIT</h5>
      </div>
    </div>
    <div class="no-kit" v-if="orderResultStatus === 0 || orderResultStatus === 1">
      <div class="mr-2">
        <no-kit-icon />
      </div>
      <div>
        <h5>NO RESULTS FOUND FOR THE SELECTED KIT</h5>
      </div>
    </div>

    <div class="waiting-result" v-if="orderResultStatus === 1">
      <div class="mr-2">
        <waiting-result-icon />
      </div>
      <div>
        <h4>AWAITING RESULTS</h4>
        <h6>The results may take 5-10 minutes to process.
            Thank you for your patience!</h6>
      </div>
    </div>
  </div>
</template>

<script>
import NoKitIcon from '@/layouts/icons/NoKitIcon.vue'
import WaitingResultIcon from '@/layouts/icons/WaitingResultIcon.vue'

export default {
  components: {
    NoKitIcon,
    WaitingResultIcon,
  },
  props: {
    orderResultStatus: {
      type: Number,
    },
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {

  },
  created() {

  },
}
</script>

<style lang="scss" scoped>
.no-result-dashboard  {
  max-width: 400px !important;
  padding: 2rem;
  margin-top: 2rem;
}

.no-kit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px !important;
  margin-bottom: 4rem;
}

.waiting-result {
  max-width: 350px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
