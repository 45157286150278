<template>
  <div class="trait-disclaimer">
    <b-alert variant="warning" show>
      <div class="alert-body">
        <span><strong>Note!</strong></span>
        <h6>This report offers insights into your genetic traits and health tendencies. 
            It is for educational purposes only and is not a substitute for medical advice, 
            diagnosis, or treatment. Your traits may be influenced by both genetic and non-genetic factors.</h6>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    orderResultStatus: {
      type: Number,
    },
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {

  },
  created() {

  },
}
</script>

<style lang="scss" scoped>
.trait-disclaimer {
  padding: 0.7rem;
}
</style>
