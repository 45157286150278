<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.5 190.5">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <circle class="cls-1" cx="96.57" cy="97.37" r="86.44"/>
        <polygon class="cls-2" points="7.14 95.25 96.57 95.25 127.71 13.32 82.94 6.97 51.34 18.85 25.46 41.47 10.13 72.45 7.14 95.25"/>
        <g>
          <path class="cls-3" d="M176.31,95.9h-21.48c-2.26,0-4.09-1.83-4.09-4.09s1.83-4.09,4.09-4.09h21.48c2.26,0,4.09,1.83,4.09,4.09s-1.83,4.09-4.09,4.09Z"/>
          <path class="cls-3" d="M91.81,180.4c-2.26,0-4.09-1.83-4.09-4.09v-21.48c0-2.26,1.83-4.09,4.09-4.09s4.09,1.83,4.09,4.09v21.48c0,2.26-1.83,4.09-4.09,4.09Z"/>
          <path class="cls-3" d="M151.56,155.65c-1.05,0-2.09-.4-2.89-1.2l-15.19-15.19c-1.6-1.6-1.6-4.19,0-5.78,1.6-1.6,4.19-1.6,5.78,0l15.19,15.19c1.6,1.6,1.6,4.19,0,5.78-.8,.8-1.85,1.2-2.89,1.2h0Z"/>
          <path class="cls-3" d="M32.06,155.65c-1.05,0-2.09-.4-2.89-1.2-1.6-1.6-1.6-4.19,0-5.78l15.19-15.19c1.6-1.6,4.19-1.6,5.78,0,1.6,1.6,1.6,4.19,0,5.78l-15.19,15.19c-.8,.8-1.85,1.2-2.89,1.2Z"/>
        </g>
        <circle class="cls-3" cx="96.57" cy="97.37" r="13.63"/>
        <path class="cls-3" d="M95.25,190.5C42.73,190.5,0,147.77,0,95.25S42.73,0,95.25,0s95.25,42.73,95.25,95.25-42.73,95.25-95.25,95.25Zm0-176.21C50.61,14.29,14.29,50.61,14.29,95.25s36.32,80.96,80.96,80.96,80.96-36.32,80.96-80.96S139.89,14.29,95.25,14.29Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>        
svg {
  height: 60px;
}

.cls-1 {
  fill: #fff;
}

.cls-2 {
  fill: #2d2d2d;
}

.cls-3 {
  fill: #9e9e9e;
}

.dark-layout {
  .cls-1 {
    fill: #1bbfcc;
  }

  .cls-2 {
    fill: #13181c;
  }

  .cls-3 {
    fill: #1d2328;
    stroke: #1bbfcc;
    stroke-miterlimit: 10;
  }
}
</style>
