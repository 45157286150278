<template>
  <div class="main-trait">
    <trait-disclaimer v-if="selectedTraitResult.orderResultStatus > 1" />
    <div v-if="selectedTraitResult.orderResultStatus > 1" class="trait-container mb-2" >
      
      <div class="d-flex align-items-center justify-content-between">
        <div class="trait-types-card">
          <div class="trait-type-item" @click="onChangTab(TRAITS_RESULT_TABS.TRAITS.code)"
            :class="{'active': selectedResultType == TRAITS_RESULT_TABS.TRAITS.code}">
            Traits
          </div>
          <div class="trait-type-item ml-2"  @click="onChangTab(TRAITS_RESULT_TABS.VITAMINS_MINERALS.code)"
            :class="{'active': selectedResultType == TRAITS_RESULT_TABS.VITAMINS_MINERALS.code}">
            Vitamins & Minerals
          </div>
        </div>
        <trait-result-filter v-if="$store.state.appConfig.layout.windowWidth >= 769"/>
      </div>

      <traits v-if="selectedResultType === TRAITS_RESULT_TABS.TRAITS.code" :categoryType="1"/>
      <traits v-if="selectedResultType === TRAITS_RESULT_TABS.VITAMINS_MINERALS.code" :categoryType="2"/>
    </div>
    <div  v-if="selectedTraitResult.orderResultStatus  <= 1 || selectedTraitResult.orderResultStatus === null">
      <no-result-page :orderResultStatus="selectedTraitResult.orderResultStatus"/>
    </div>
  </div>
</template>

<script>
import { TRAITS_RESULT_TABS } from '@/common/config'
import Traits from './Traits/Traits.vue';
import NoResultPage from './pages/NoResultPage.vue'
import TraitResultFilter from './TraitResultFilter.vue'
import TraitDisclaimer from './pages/TraitDisclaimer.vue';

export default {
  components: {
    Traits,
    NoResultPage,
    TraitResultFilter,
    TraitDisclaimer,
  },
  data() {
    return {
      TRAITS_RESULT_TABS,
    }
  },
  methods: {
    onInitResultTabs() {
      if (this.selectedResultType === TRAITS_RESULT_TABS.TRAITS.code) {
        setTimeout(() => { 
          if (this.$root.$refs.Traits) {
            this.$root.$refs.Traits.onLoadTraitsResults();
          }
        });
      }
      if (this.selectedResultType === TRAITS_RESULT_TABS.VITAMINS_MINERALS.code) {
        setTimeout(() => { 
          if (this.$root.$refs.Traits) {
            this.$root.$refs.Traits.onLoadTraitsResults();
          }
        });
      }
    },
    onChangTab(tab) {
      this.$store.state.traitsAndHealths.selectedTab = tab;
      this.$store.state.traitsAndHealths.selectedTraitCategory = {};
      this.onInitResultTabs();
    },
  },
  computed: {
    selectedTraitResult() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResult'];
    },
    selectedResultType() {
      return this.$store.state.traitsAndHealths.selectedTab;
    },
  },
  watch: {
    selectedTraitResult(newValue, oldValue) {
      this.onInitResultTabs();
    },
  },
  created() {
    if (this.selectedTraitResult) {
      this.onInitResultTabs();
    }
  },
}
</script>

<style lang="scss">
@import './traits.scss';
</style>

<style lang="scss" scoped>
.trait-container {
  width:100%;
  padding: 2.4rem;
  .trait-types-card {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .trait-type-item {
      color: var(--lab-black);
      font-weight: bold;
      font-size: 1rem;
      cursor: pointer;
      padding-bottom: 5px;
    }

    .trait-type-item.active {
      padding-bottom: 5px;
      border-bottom: 5px solid var(--lab-fight-sunrise-color);
    }
  }
}

.dark-layout .trait-container .trait-types-card .trait-type-item {
  color: var(--lab-doctor);
}

@media screen and (min-width: 770px) { 
 .trait-container  {
    background-color: var(--bg-card-color);
    border: var(--lab-card-border);
    box-shadow: var(--lab-card-shadow);
    border-radius: 15px;
    height: 100%;
 }
}
@media screen and (max-width: 769px) { 
 .trait-container  {
    padding: 0 1.5rem;
    .trait-types-card { 
      display: flex;
      align-items: center;
      justify-content: center;

      .trait-type-item {
        font-size: 1.3rem;
      }
    }
 }
}
</style>
